import React from "react";
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import Seo from "../components/Seo"

const DownloadWrapper = styled.div`
    border-bottom: 3px solid #720000;
    margin-bottom: 15px;
    h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }
`

const Download = () => {
    return (
    <Layout>
        <Seo 
            title="Download สำหรับลูกค้าเรา | Kickoffx999"
            description="Download สำหรับลูกค้าเรา Kickoffx999"
            image=""
            url="https://dentist-marketing.net/promotion/"
            canonical="https://dentist-marketing.net/promotion/"
        />
        <StaticImage src="../images/hero-banner.png" alt="hero-banner" />
        <Container className="py-5">
            <Row>
                <Col md={12}>
                    <DownloadWrapper>
                        <h3>ดาวน์โหลด แอพพลิเคชั่น <span>สำหรับลูกค้าเรา</span></h3>
                    </DownloadWrapper>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div>
                        <h3>Android</h3>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <h3>Apple iOS</h3>
                    </div>
                </Col>
            </Row>
            <Row>
                
            </Row>
        </Container>
    </Layout>
)};

export default Download;